





































































































































































































import { Vue, Component } from 'vue-property-decorator'
import validation from '@/common/element-val'
import { ElForm } from 'element-ui/types/form'
import settingService, { EmailModel, Pop3OrSmtpModel } from '../../services/settingService'
import { MessageText } from '@/services/data'
import Msg from '@/components/Msg.vue'

@Component({
  components: {
    Msg
  }
})
export default class Mail extends Vue {
  $refs: {
    pop3Form: ElForm;
    smtpForm: ElForm;
    emailForm: ElForm;
  }

  pop3: Pop3OrSmtpModel = {
    Server: '',
    Port: null,
    Ssl: false
  }

  smtp: Pop3OrSmtpModel = {
    Server: '',
    Port: null,
    Ssl: false
  }

  email: EmailModel = {
    Reg: null,
    RegPwd: null,
    Statement: null,
    StatementPwd: null,
    TradeConfirm: null,
    TradeConfirmPwd: null
  }

  message: MessageText = null

  rules = {
    pop3: {
      Server: validation.requiredRule(),
      Port: validation.requiredRule()
    },
    smtp: {
      Server: validation.requiredRule(),
      Port: validation.requiredRule()
    },
    email: {
      Reg: validation.requiredEmailRule()
    }
  }

  async mounted () {
    const res = await settingService.readSysSettingByNamesAsync(['Pop3', 'Smtp', 'SysEmailAccount'])
    const result = res.Result
    if (result && result.length > 0) {
      this.pop3 = this.parseJson(result, 'Pop3') as Pop3OrSmtpModel
      this.smtp = this.parseJson(result, 'Smtp') as Pop3OrSmtpModel
      this.email = this.parseJson(result, 'SysEmailAccount') as EmailModel
    }
  }

  parseJson (items: Array<{ Name: string; Value: string }>, name: string): any {
    const item = items.find(p => p.Name === name)
    if (item) {
      return JSON.parse(item.Value)
    }
    return null
  }

  async testPop3 () {
    this.$refs.pop3Form.validate(async (valid) => {
      if (valid) {
        const res = await settingService.testPop3(this.pop3)
        if (res.Error) {
          this.message = { error: res.Error }
        } else {
          this.message = { success: this.$t('msg.pop3Tested') as string }
        }
      }
    })
  }

  async testSmtp () {
    this.$refs.smtpForm.validate(async (valid) => {
      if (valid) {
        const res = await settingService.testSmtp(this.smtp)
        if (res.Error) {
          this.message = { error: res.Error }
        } else {
          this.message = { success: this.$t('msg.smtpTested') as string }
        }
      }
    })
  }

  async submit () {
    this.$refs.smtpForm.validate(async (valid) => {
      if (valid) {
        const res = await settingService.addOrUpdateSysSettingAsync([{
          Name: 'Smtp',
          Value: JSON.stringify(this.smtp),
          ValueType: 'Json'
        }])
        if (res.Result) {
          this.message = { success: this.$t('msg.settingSaved') as string }
        } else {
          this.message = { error: this.$t('msg.unableToSave') as string }
        }
      }
    })
    this.$refs.pop3Form.validate(async (valid) => {
      if (valid) {
        const res = await settingService.addOrUpdateSysSettingAsync([{
          Name: 'Pop3',
          Value: JSON.stringify(this.pop3),
          ValueType: 'Json'
        }])
        if (res.Result) {
          this.message = { success: this.$t('msg.settingSaved') as string }
        } else {
          this.message = { error: this.$t('msg.unableToSave') as string }
        }
      }
    })
    this.$refs.emailForm.validate(async (valid) => {
      if (valid) {
        const res = await settingService.addOrUpdateSysSettingAsync([{
          Name: 'SysEmailAccount',
          Value: JSON.stringify(this.email),
          ValueType: 'Json'
        }])
        if (res.Result) {
          this.message = { success: this.$t('msg.settingSaved') as string }
        } else {
          this.message = { error: this.$t('msg.unableToSave') as string }
        }
      }
    })
  }
}
