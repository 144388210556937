import serviceBase from '@/services/serviceBase'
import { ApiBooleanResult, ApiResult } from '@/services/data'

export interface Pop3OrSmtpModel {
  Server: string;
  Port: number;
  Ssl: boolean;
}

export interface EmailModel {
  Reg: string;
  RegPwd: string;
  Statement: string;
  StatementPwd: string;
  TradeConfirm: string;
  TradeConfirmPwd: string;
}

export interface SettingValueModel {
  Name: string;
  Value: string;
  ValueType: string;
}

export default {
  async readSysSettingByNamesAsync (names: Array<string>): Promise<ApiResult<SettingValueModel[]>> {
    return await serviceBase.tryPostResult<SettingValueModel[]>('/Setting/readSysSettingByNames', names)
  },
  async addOrUpdateSysSettingAsync (settings: SettingValueModel[]): Promise<ApiBooleanResult> {
    return await serviceBase.tryPostBooleanResult('/Setting/addOrUpdateSysSetting', settings)
  },
  async readUserSettingsByNamesAsync (names: Array<string>): Promise<ApiResult<SettingValueModel[]>> {
    return await serviceBase.tryPostResult<SettingValueModel[]>('/Setting/readUserSettingsByNames', names)
  },
  async addOrUpdateUserSettingAsync (settings: SettingValueModel[]): Promise<ApiBooleanResult> {
    return await serviceBase.tryPostBooleanResult('/Setting/addOrUpdateUserSetting', settings)
  },
  async testPop3 (data: Pop3OrSmtpModel): Promise<ApiBooleanResult> {
    return await serviceBase.tryPostBooleanResult('/Setting/testPop', data)
  },
  async testSmtp (data: Pop3OrSmtpModel): Promise<ApiBooleanResult> {
    return await serviceBase.tryPostBooleanResult('/Setting/testSmtp', data)
  }
}
