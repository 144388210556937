






import { MessageText } from '@/services/data'
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class Msg extends Vue {
  name = 'msg'
  @Prop() message: MessageText
}
